/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://e6i25m7swd.execute-api.eu-west-2.amazonaws.com/multisite",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://6u78rtp6j7.execute-api.eu-west-2.amazonaws.com/multisite",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://3e6qnml754.execute-api.eu-west-2.amazonaws.com/multisite",
            "region": "eu-west-2"
        },
        {
            "name": "reminderEmail",
            "endpoint": "https://bm11vqlfw4.execute-api.eu-west-2.amazonaws.com/multisite",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://cfbuhv4yyreozmbhr6t7hiocc4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:d44fd2b4-c466-41de-999b-cfcbaa966fb3",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_lM2yL6ZEu",
    "aws_user_pools_web_client_id": "1hutn50kq9r98esq7tvbuce18t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c94328-multisite",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
